import { getCountryFromContext } from "./getCountryFromContext";

// Condition for handling the `country` archetype.
function countryArchetypeCondition(sitecoreContext) {
  // This should be used to extract the country from the Sitecore context
  // that is provided by Akamai.
  const resolveCountry = () => {
    return getCountryFromContext(sitecoreContext);
  };

  // Return matches from the country the user is tagged with.
  const getValues = ({ mappings, sitecoreContext }) => {
    const country = resolveCountry(sitecoreContext);
    return mappings[country] || [];
  };

  // Expose a way to retrieve the values from this condition.
  return {
    getValues,
  };
}

// A collection of all Archetypes we support.
const archetypeConditions = (sitecoreContext) => {
  return {
    country: countryArchetypeCondition(sitecoreContext),
  };
};

export const resolveListPersonalization = (() => {
  return (intents, sitecoreContext) => {
    // Iterate all of the personalization rules applied
    const conditions = intents?.targetItems
      .map((i) => {
        // The identifier value for this Archetype, this matches the value in the
        // `archetypeConditions` object.
        const identifier = i.identifier.value;

        // Check if we have a handler
        let handler = archetypeConditions(sitecoreContext)[identifier];

        // If not, warn and just return null.
        if (!handler) {
          console.warn(
            `personalization handler for ${identifier} not defined.`
          );
          return null;
        }

        // Extract all of the mappings for this Archetype which is a mapping between a
        // string value and a tag.
        const mappings = {};
        i.children.forEach((mapping) => {
          const { value, tags } = mapping;
          mappings[value?.value] = tags.targetItems.map(
            (tag) => tag?.name?.value
          );
        });

        // Return the identifier/handler/mappings for this condition
        return {
          identifier: identifier,
          handler,
          mappings,
        };
      })
      .filter((condition) => condition); // Filter nulls from unsupported!

    return conditions;
  };
})();
