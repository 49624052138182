import { dataFetcher } from './dataFetcher';
import Cookies from 'js-cookie';
import { getItem, setItem } from './accessibilityStateManager';

const userCookieName = 'user_session';
interface Videos {
    userId?: string;
    videoId: string;
    like?: boolean;
    likes: number;
    timestamp?: number;
}
export interface State {

    darkMode: boolean;
    volume: number;
    videosList: Array<Videos>;
}

export interface Action {
    type: string;
    payload: any;
    error?: Error;
}

const userSettingsFetcher = (requestURL: string, data: any) => {
    const cookieToken = getUserCookie();

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    cookieToken && myHeaders.append('Authorization', `Bearer ${cookieToken.access_token}`);

    var requestOptions: any = {
        headers: myHeaders,
        //redirect: 'follow',
    };
    const requestProps: Partial<RequestInit> = {
        method: data ? 'POST' : 'GET',

        body: JSON.stringify(data),
        ... requestOptions
      };


      return fetch(requestURL, requestProps).then((response) => {
        return {
          status: response.status,
          statusText: response.statusText,
          data: response.json(),
        };
      });

}

export const getUserCookie = () => {
    let parsedCookie,
        status = false;

    const cookie = Cookies.get(userCookieName);

    if (cookie) {
        try {
            parsedCookie = JSON.parse(cookie || '');
            if (parsedCookie.access_token) {

                status = true;
            }

        } catch (e) { }
    }
    if (status) {
        return parsedCookie;
    }

    return false;
};

export const reducer = (state: State, action: Action) => {
    switch (action.type) {

        case 'theme-changed': {
            return { ...state, darkMode: action.payload };
        }
        case 'video-like': {

            return {
                ...state,
                videosList: state.videosList.map(
                    (item) => item.videoId == action.payload ? { ...item, like: action.payload }
                        : item
                )
            };
        }
        case 'video-list': {
            return {
                ...state,
                videosList: action.payload
            };

        }
        case 'video-list-addItems': {

            return {
                ...state,
                videosList: [...state.videosList, ...action.payload.filter((itemResponse: any) => (
                    state.videosList.findIndex((itemVideoList: any) => itemResponse.videoId == itemVideoList.videoId) == -1
                ))]

            };

        }
        case 'video-volume': {
            return { ...state, volume: action.payload };
        }
        default: {
            return state;
        }
    }
};

export type FetchUserSettingsFunction = (
    identityPath: string
) => (dispatch: any) => Promise<any> | void;

export type FetchVideoListFunction = (
    identityPath: any,
    videoId?: any,
    videoIds?: any,
    clientId?: any,
    timestamp?: any,
) => (dispatch: any) => (Promise<any> | void);

export const fetchVideosList: FetchVideoListFunction = (identityPath: string) => {
    return () => {
        // Signal that we're about to start fetching data. Typically use this
        // to set loading state to `true`.
        const cookieToken = getUserCookie();

        let myHeaders = new Headers();

        myHeaders.append('Authorization', `Bearer ${cookieToken}`);
        myHeaders.append('Content-Type', 'application/json');

        var requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            //body: {},
            redirect: 'follow',
        };
        dataFetcher(`${identityPath}/graphql/video`, null, requestOptions)
            .then(() => {
                // console.log(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
};
/*eslint-disable */
export const getLikes: FetchVideoListFunction = (videoIds?: any, identityPath?: string) => {

    return (dispatch: any) => {

        const query =  {
            query: `{ videos(ids:${JSON.stringify(videoIds)}){ userId, videoId, like, likes, timestamp }}`
          }


        userSettingsFetcher(`${identityPath}/graphql/video`, query)
          .then((data: any) => {
              data.data.then((res: any) => {
                  const videos = res.data.videos;
              // @ts-ignore
              let videoList = storageManager.getVideosList() && JSON.parse(storageManager.getVideosList()) || [];

              videos.map((element: any) => {
                  const currentVideoIndex = videoList.findIndex((item: any) => item.videoId == element.videoId);
                  if(currentVideoIndex == -1) {
                    videoList.push(element)
                  } else {
                    videoList[currentVideoIndex] = {
                        ...videoList[currentVideoIndex],
                        ...element
                    }
                  }
              });
              storageManager.setVideosList(videoList);
              dispatch({
                  type: 'video-list',
                  payload: videoList
              });
              })

          })
          .catch((error: any) => {
              console.log(error)
          })
    };
};



export const setLike: FetchVideoListFunction = (videoId: any, identityPath: string) => {

    return (dispatch: any) => {
        let videoList = storageManager.getVideosList() && JSON.parse(storageManager.getVideosList()) || [];
        // @ts-ignore
        const cookieToken = getUserCookie();
        let videoLikeValue = true;
        videoList.some((v: any) => {
            if (v.videoId === videoId) {
                videoLikeValue = !v.like;
                v.like = !v.like;
                v.like ? v.likes++ : v.likes--;

            }

        });

        storageManager.setVideosList(videoList);
        dispatch({
            type: 'video-list',
            payload: videoList
        });
        if (cookieToken) {
            const query =  {
                query: `mutation updateVideo{ like(videoId:${JSON.stringify(videoId)}, enabled:${JSON.stringify(videoLikeValue)}){userId,videoId,timestamp,like,likes}}`,
                variables: {}
            }

            userSettingsFetcher(`${identityPath}/graphql/video`, query)
        }

    };
};
export const setVideoTimestamp: FetchVideoListFunction = (videoId?: string, timestamp?: any, identityPath?: number) => {

    return (dispatch: any) => {
        let videoList = storageManager.getVideosList() && JSON.parse(storageManager.getVideosList()) || [];
        // @ts-ignore
        const cookieToken = getUserCookie();
        videoList.some((v: any) => {
            if (v.videoId === videoId) {
                v.timestamp = timestamp;

            }

        });

        if (cookieToken) {
            storageManager.setVideosList(videoList);
            dispatch({
                         type: 'video-list',
                         payload: videoList
                     });
        }
        if (cookieToken) {
            const query =  {
                query: `mutation updateVideo{ timestamp(videoId:${JSON.stringify(videoId)}, value:${JSON.stringify(parseInt(timestamp))}){userId,videoId,timestamp,like,likes}}`,
                variables: {}
            }
            userSettingsFetcher(`${identityPath}/graphql/video`, query)
        }
    };
};
/*eslint-disable */
export const fetchUserSettings: FetchUserSettingsFunction = (identityPath: string) => {
    return (dispatch) => {
        // Signal that we're about to start fetching data. Typically use this
        // to set loading state to `true`.
        const cookieToken = getUserCookie();

        if (cookieToken) {
            const query =  {
                query: `{ settings{ darkMode,
                volume}}`
            }

            // @ts-ignore
            // @ts-ignore

            userSettingsFetcher(`${identityPath}/graphql/video`, query)
            .then((data: any) => {

                data.data.then((res: any) => {
                    storageManager.setDarkMode(res.data.settings.darkMode);
                    storageManager.setVolume(res.data.settings.volume);
                    dispatch({
                        type: 'video-volume',
                        payload: res.data.settings.volume,
                    });
                    dispatch({
                        type: 'theme-changed',
                        payload: res.data.settings.darkMode,
                    });

                })
            })
        }
    };
};

export type ChangeThemeFunction = (
    value: boolean,
    identityPath: string
) => (dispatch: any, getState?: any) => Promise<any> | void;

export const changeThemeFunction: ChangeThemeFunction = (value: boolean, identityPath: string) => {

    return (dispatch: any) => {
        const cookieToken = getUserCookie();
        storageManager.setDarkMode(value);
        dispatch({
            type: 'theme-changed',
            payload: value,
        });
        if (cookieToken) {

            const query =  {
                query: `mutation updateSettings{ darkMode(enabled: ${value}){ darkMode,
                volume}}`,
                variables: {}
            }

            userSettingsFetcher(`${identityPath}/graphql/video`, query)

        }
    };

};
export const likeVideoFunction = (videoId: string) => {
    return (dispatch: any) => {

        dispatch({
            type: 'video-like',
            payload: videoId,
        });
    };

};
export const setVideoVolumeFunction = (value: number, identityPath: string) => {
    // @ts-ignore
    return (dispatch: any) => {
        storageManager.setVolume(value);
        dispatch({
            type: 'video-volume',
            payload: value,
        });
        const cookieToken = getUserCookie();
        if (cookieToken) {
            const query = {
                query: `mutation updateSettings{
                    volume(value: ${value}) {userId, darkMode, volume}
                }`,
                variables: {}
            };

            userSettingsFetcher(`${identityPath}/graphql/video`, query)

        }
    };
};
export const resolveUserSettings = () => {
    return {
        darkMode: storageManager.getDarkMode() === 'true',
        volume: Number(storageManager.getVolume()) || 0,
        videosList: storageManager.getVideosList() && JSON.parse(storageManager.getVideosList()) || []
    };
};
export const storageManager = {
    getDarkMode: () => getItem('dtcm-dark-mode'),
    setDarkMode: (value: boolean) => { setItem('dtcm-dark-mode', value); },
    getVolume: () => getItem('dtcm-video-volume'),
    setVolume: (value: number) => setItem('dtcm-video-volume', value),
    getVideosList: () => getItem('dtcm-video-list') || '',
    setVideosList: (value: Array<Videos>) => setItem('dtcm-video-list', JSON.stringify(value))
};

