export const algoliaScoredCondition = (matchedCategories) => {
    return {
        weight: 1, // No weighting on this field, pass as a param if there are multiple conditions and this field should be weighted above or below others.
        rule: ({ item }) => {
            // Algolia results have a tag field, it's a string array.
            const tags = item.tags || [];
            
            // Determine which tags the current item matches
            const matches = matchedCategories.find(tag => {
                return tags.indexOf(tag) > -1;
            })
            
            // Just return the Algolia score here and any item matches.
            return {
                score: item._rankingInfo.filters,
                matches
            }
        }
    }
}