import { ContrastSwitchOption } from '@dtcm/ui-components';

export interface State {
  selectedFontSize: string;
  selectedContrastValue: string;
  isPersonalised: boolean | null;
}

export interface Action {
  type: string;
  payload: any;
  error?: Error;
}

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'accessibility-settings-initialized': {
      return state;
    }
    case 'font-size-changed': {
      return { ...state, selectedFontSize: action.payload };
    }
    case 'contrast-value-changed': {
      return { ...state, selectedContrastValue: action.payload };
    }
    case 'pesonalisation-value-changed': {
      return { ...state, isPersonalised: action.payload };
    }

    default: {
      return state;
    }
  }
}

// This function is a bit superfluous, but allows us to centralize the actions
// associated with adjusting accessibility settings.
export const initializeAccessibilitySettings: InitializeAccessibilitySettingsFunction = ({
  contrast,
  fontSize,
}) => {
  return (dispatch: any) => {
    setContrast({ theme: { value: contrast }, title: { value: '' } });
    setFontSize(fontSize);
    dispatch({
      type: 'accessibility-settings-initialized',
    });
  };
};

export type InitializeAccessibilitySettingsFunction = ({
  contrast,
  fontSize,
}: {
  contrast: string;
  fontSize: FontSize;
}) => void;

export type FontSize = 'z100' | 'z150' | 'z200';

export const fontSizeClasses = ['z100', 'z150', 'z200'];

export type ChangeFontSizeFunction = (
  fontSize: FontSize
) => (dispatch: any, getState?: any) => Promise<any> | void;

export const changeFontSize: ChangeFontSizeFunction = (fontSize: FontSize) => {
  return (dispatch: any) => {
    setFontSize(fontSize);

    dispatch({
      type: 'font-size-changed',
      payload: fontSize,
    });
  };
};

const setFontSize = (fontSize: FontSize) => {
  if (typeof window !== 'undefined') {
    document.querySelector('html')!.classList.remove(...fontSizeClasses);
    document.querySelector('html')!.classList.add(fontSize);

    storageManager.setFontSize(fontSize);
  }
};

export type ChangeContrastFunction = (
  option: ContrastSwitchOption
) => (dispatch: any, getState?: any) => Promise<any> | void;

export const changeContrast: ChangeContrastFunction = (option: ContrastSwitchOption) => {
  return (dispatch: any) => {
    setContrast(option);
    dispatch({
      type: 'contrast-value-changed',
      payload: option.theme.value,
    });
  };
};
export type setPersonalisationFunction = (
  value: boolean
) => (dispatch: any, getState?: any) => void;

export const setPersonalisation: setPersonalisationFunction = (value: boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: 'pesonalisation-value-changed',
      payload: value,
    });
  };
};
const setContrast = (option: ContrastSwitchOption) => {
  if (typeof window !== 'undefined') {
    if (option.theme.value === 'theme1') {
      document.querySelector('body')!.classList.remove('contrast');
    } else if (option.theme.value === 'theme2') {
      document.querySelector('body')!.classList.add('contrast');
    }
  }

  storageManager.setContrast(option.theme.value);
};

export function resolveAccessibilitySettings(config: any) {
  return {
    contrast: storageManager.getContrast() || config.defaultContrast,
    fontSize: storageManager.getFontSize() || config.defaultFontSize,
  };
}

export const storageManager = {
  getFontSize: () => getItem('dtcm-font-size'),
  setFontSize: (value: string) => setItem('dtcm-font-size', value),
  getContrast: () => getItem('dtcm-contrast'),
  setContrast: (value: string) => setItem('dtcm-contrast', value),
};

export function getItem(key: string) {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key);
  }
  return null;
}

export function setItem(key: string, value: any) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value);
  }
}
