export const getCountryFromContext = (sitecoreContext) => {
  let country_code;
  const edgescape = sitecoreContext?.headers?.akamai?.edgescape;
  if (edgescape) {
    const segments = edgescape.split(",");
    segments.forEach((pair) => {
      pair = pair.split("=");
      switch (pair[0]) {
        case "country_code":
          country_code = pair[1];
          break;
        default:
      }
    });
  }
  return country_code;
};
