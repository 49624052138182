import React from 'react';
import { NavigationBar, NavigationBarProps } from '@dtcm/ui-components';
import { useAppStateContext } from '../../lib/AppStateProvider';
import { get } from '../../lib/getPropertyByPath';

export const NavBarContainer = () => {
  const { appState, actions } = useAppStateContext();

  if (!appState.navbar || get(appState, 'dictionary.viewBag.isEmptyLayout', false)) {
    return null;
  }

  const dictionary = get(appState, 'navbarData');
  const dictionaryVenues = get(appState, 'dictionary.viewBag.navbar.venueFormsDictionary');
  const commonDictionary = get(appState, 'dictionary.viewBag.navbar.commonDictionary');

  const activeLanguage = {
    languageCode: get(appState, 'language.languageCode'),
    languageName: get(appState, 'language.languageName'),
  };

  // Map data received from Sitecore to props expected by NavBar component.
  // Also provide functions to handle actions triggered within the NavBar.

  const props: NavigationBarProps = {
    activeLanguage,
    analytics: get(appState, 'analytics'),
    breadcrumbAnalytics: get(appState, 'sitecoreContextData.breadcrumbAnalytics'),
    searchAnalytics: get(appState, 'sitecoreContextData.searchAnalytics'),
    mainNav: {
      removeVenueFromShortlistAnalytics: get(appState, 'dictionary.viewBag.navbar.removeVenueFromShortlistAnalytics'),
      shareYourShortlistAnalytics: get(appState, 'dictionary.viewBag.navbar.shareYourShortlistAnalytics'),
      requestProposalAnalytics: get(appState, 'dictionary.viewBag.navbar.requestProposalAnalytics'),
      yourShortlistedBottomAnalytics: get(appState, 'dictionary.viewBag.navbar.yourShortlistedBottomAnalytics'),
      yourShortlistedTopAnalytics: get(appState, 'dictionary.viewBag.navbar.yourShortlistedTopAnalytics'),
      interactionWithVenueRPFormAnalytics: get(appState, 'dictionary.viewBag.navbar.interactionWithVenueRPFormAnalytics'),
      mainNavItems: get(appState, 'navbar.mainNavItems'),
      primaryBrandLogo: get(appState, 'navbar.logoImage'),
      primaryBrandLogoLink: get(appState, 'navbar.primaryBrandLogoLink'),
      secondaryBrandLogo: get(appState, 'navbar.secondLogoImage'),
      secondaryBrandLogoLink: get(appState, 'navbar.secondaryBrandLogoLink'),
      accountLinks: get(appState, 'navbar.accountLinks'),
      menuLinks: get(appState, 'navbar.menuLinks'),
      venueShortlistVisible: false,
      setVenueShortlistVisible: Function,
      venueShortlists: [],
      transparentNavigation: get(appState, 'routeData.fields.headTransparent.value'),
    },

    dictionary: {
      dictionary,
      dictionaryVenues,
    },
    accessibilitySettings: {
      targetItem: get(appState, 'navbar.accessibilitySettings.targetItem'),
      selectedContrastValue: appState.selectedContrastValue,
      selectedFontSize: appState.selectedFontSize,
      readSpeakerScriptPath: `${process.env.PUBLIC_URL}/assets/scripts`,
      onContrastOptionSelected: (option:any) => actions.changeContrast(option),
      onFontSizeSelected: (fontSize: any) => actions.changeFontSize(fontSize),
    },
    languageSelector: {
      targetItem: get(appState, 'navbar.languageSelector.targetItem'),
      activeLanguage,
      currentPath: appState.routePath,
    },
    topNav: {
      accessibilitySettingsTitle: get(appState, 'navbar.accessibilitySettings.targetItem.title.value', ''),
      globalLinks: get(appState, 'navbar.globalLinks'),
      activeLanguageName: activeLanguage.languageName,
    },
    breadcrumb: {
      mobileLabel: get(appState, 'navbar.mobileBreadcrumbLabel.value.value'),
    },
    search: {
      targetItem: get(appState, 'navbar.search.targetItem'),
      dictionary: commonDictionary

    },
    login: {
      title: 'Login',
    },
    transparentNavigation: get(appState, 'routeData.fields.headTransparent.value'),
  };

  return <NavigationBar {...props} />;
};
