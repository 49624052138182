import React from 'react';
import { SystemMessageBanner } from '@dtcm/ui-components';

const NotFoundContent: any = {
  en: {
    fields: {
      title: {
        value: 'Sorry, we couldn’t find that page',
      },
      description: {
        value: 'Please recheck the URL or return to the homepage.',
      },
      ctaLabel: {
        value: 'Return to home page',
      },
      ctaLink: {
        value: {
          href: '/',
          anchor: '',
          linktype: 'internal',
          class: '',
          title: '',
          querystring: '',
          id: '{A98A98BD-AF22-5239-A16E-DEEF268ED6A4}',
        },
      },
      colourTheme: {
        id: '3f7e5570-cd7e-46da-94e7-d9267e7791d1',
        url: '/SiteSettings/Theme/Colors/theme-1',
        fields: {
          backgroundColor: {
            value: '#0067b9',
          },
          themeName: {
            value: 'theme-1',
          },
        },
      },
    },
  },
  ar: {
    fields: {
      title: {
        value: 'عذراً، الصفحة التي تُحاول الوصول إليها غير مُتاحة حاليّاً',
      },
      description: {
        value: 'الرجاء التحقّق من الرابط أو العودة إلى الصفحة الرئيسيّة',
      },
      ctaLabel: {
        value: 'العوده للصفحة الرئيسية',
      },
      ctaLink: {
        value: {
          href: '/',
          anchor: '',
          linktype: 'internal',
          class: '',
          title: '',
          querystring: '',
          id: '{A98A98BD-AF22-5239-A16E-DEEF268ED6A4}',
        },
      },
      colourTheme: {
        id: '3f7e5570-cd7e-46da-94e7-d9267e7791d1',
        url: '/SiteSettings/Theme/Colors/theme-1',
        fields: {
          backgroundColor: {
            value: '#0067b9',
          },
          themeName: {
            value: 'theme-1',
          },
        },
      },
    },
  },
};

export interface NotFoundProps {
  context: {
    language: string;
  };
}

export const NotFound: React.FunctionComponent<NotFoundProps> = ({ context }) => {
  const langauge = context && context.language ? context.language : 'en';
  const props = NotFoundContent[langauge];
  return <SystemMessageBanner {...props} />;
};
